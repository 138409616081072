import { User } from '@auth0/auth0-react';

import { Identity } from 'src/api/endpoints/storeApi.types';
import { RmClaims } from 'src/api/queries/user';

import { isValidKey } from './isValidKey';

const getEmail = (data: User) => data?.email ?? '';
const isEmailVerified = (data: User) => data.email_verified === true;

const getEmailFromClaims = (data: RmClaims) =>
  data['https://auth.remarkable.com/email'] ?? '';

const getLoginCount = (data: User | RmClaims) =>
  data['https://auth.remarkable.com/logins_count'];

const getLinkableUserId = (data: User | RmClaims) =>
  data['https://auth.remarkable.com/linkable'];

const isLinkable = (data: User | RmClaims) => !!getLinkableUserId(data);

const getTectonicDomain = (data: User | RmClaims) =>
  data['https://auth.remarkable.com/tectonic'];

const isAuth0User = (data: User | RmClaims) => !!data.sub?.startsWith('auth0|');

const isGoogleUser = (data: User | RmClaims) =>
  !!data.sub?.startsWith('google-oauth2|');

const isAppleUser = (data: User | RmClaims) => !!data.sub?.startsWith('apple|');

const isFacebookUser = (data: User | RmClaims) =>
  !!data.sub?.startsWith('facebook|');

const isSocialUser = (data: User | RmClaims) =>
  isGoogleUser(data) || isAppleUser(data) || isFacebookUser(data);

const isSAMLUser = (data: User | RmClaims) => !!data.sub?.startsWith('samlp|');

/** Used for tracking */
const getUserAccountProvider = (data: User | RmClaims) => {
  if (isAuth0User(data)) return 'Auth0';
  if (isGoogleUser(data)) return 'Google';
  if (isAppleUser(data)) return 'Apple';
  if (isFacebookUser(data)) return 'Facebook';
  if (isSAMLUser(data)) return 'SAML';
  return 'unknown';
};

const getLinkableAccountProvider = (data: User | RmClaims) => {
  const linkableUser: User = { sub: getLinkableUserId(data) };

  return getUserAccountProvider(linkableUser);
};

const getOrganizationId = (data: User | RmClaims) =>
  data['https://auth.remarkable.com/organization'];

const getOrganizationConnection = (data: User) =>
  data['https://auth.remarkable.com/connection'];

const getOrganizationDisplayName = (data: User) =>
  data['https://auth.remarkable.com/organization_display_name'];

const isOrganizationMember = (data: User | RmClaims) =>
  !!getOrganizationId(data);

const getLoggedInOrganizationId = (data: User | RmClaims) => data['org_id'];

const isLoggedInToOrganization = (data: User | RmClaims) =>
  !!getLoggedInOrganizationId(data);

const getDomainClaimedConnection = (data: User | RmClaims) =>
  data['https://auth.remarkable.com/domain_claimed_by_connection'];

const isDomainClaimedByConnection = (data: User | RmClaims) =>
  !!getDomainClaimedConnection(data);

const hasActiveEnterpriseEnrollment = (data: User | RmClaims) =>
  !!data['https://auth.remarkable.com/enrolling_enterprise_connection'];

const connectionByIdentityProvider = {
  auth0: 'Username-Password-Authentication',
  'google-oauth2': 'google-oauth2',
  apple: 'apple',
  facebook: 'facebook',
};

const getConnectionFromUserId = (data: User | RmClaims) => {
  if (!data || typeof data.sub !== 'string') return;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const provider = data.sub.split('|')[0];

  if (isValidKey(provider, connectionByIdentityProvider)) {
    return connectionByIdentityProvider[provider];
  }

  return;
};

const getSAMLIdentity = (identities: Identity[]) =>
  identities.find((i) => i.provider === 'samlp');

const hasSAMLIdentity = (identities: Identity[]) =>
  !!getSAMLIdentity(identities);

const isAccountRestrictedToAccountLinking = (data: User | RmClaims) =>
  isSAMLUser(data) && isLinkable(data);

const canEditName = (data: User | RmClaims, identities: Identity[]) =>
  isAuth0User(data) && !hasSAMLIdentity(identities);

const canEditEmail = (data: User | RmClaims, identities: Identity[]) =>
  isAuth0User(data) && !hasSAMLIdentity(identities);

const canEditPassword = (data: User | RmClaims, identities: Identity[]) =>
  isAuth0User(data) && !hasSAMLIdentity(identities);

const canMigrateAccountToEnterprise = (data: User | RmClaims) =>
  isLinkable(data) || isDomainClaimedByConnection(data);

const isUnlinkedSamlAccount = (data: User | RmClaims, identities: Identity[]) =>
  isSAMLUser(data) && identities.length === 1;

const hasSubscription = (data: User | RmClaims) =>
  !!data['https://auth.remarkable.com/subscription'];

const getPermissions = (data: RmClaims) => data['permissions'];

const hasPermissionReadMembers = (data: RmClaims) =>
  !!getPermissions(data)?.includes('read:members');

const hasPermissionReadOrganization = (data: RmClaims) =>
  !!getPermissions(data)?.includes('read:organization');

const hasPermissionReadRoleAssignments = (data: RmClaims) =>
  !!getPermissions(data)?.includes('read:role_assignments');

const hasPermissionCreateRoleAssignments = (data: RmClaims) =>
  !!getPermissions(data)?.includes('create:role_assignments');

const hasPermissionDeleteRoleAssignments = (data: RmClaims) =>
  !!getPermissions(data)?.includes('delete:role_assignments');

const hasPermissionAccessEnterpriseBeta = (data: RmClaims) =>
  !!getPermissions(data)?.includes('access:enterprise_beta');

const hasPermissionReadInvitations = (data: RmClaims) =>
  !!getPermissions(data)?.includes('read:invitations');

const hasPermissionCreateInvitations = (data: RmClaims) =>
  !!getPermissions(data)?.includes('create:invitations');

const hasPermissionUpdateOrgSettings = (data: RmClaims) =>
  !!getPermissions(data)?.includes('update:organization_settings');

export const userHelpers = {
  canEditEmail,
  canEditName,
  canEditPassword,
  canMigrateAccountToEnterprise,
  getConnectionFromUserId,
  getDomainClaimedConnection,
  getEmail,
  getEmailFromClaims,
  getLinkableAccountProvider,
  getLinkableUserId,
  getLoggedInOrganizationId,
  getLoginCount,
  getOrganizationConnection,
  getOrganizationDisplayName,
  getOrganizationId,
  getPermissions,
  getSAMLIdentity,
  getTectonicDomain,
  getUserAccountProvider,
  hasActiveEnterpriseEnrollment,
  hasPermissionAccessEnterpriseBeta,
  hasPermissionCreateInvitations,
  hasPermissionCreateRoleAssignments,
  hasPermissionDeleteRoleAssignments,
  hasPermissionUpdateOrgSettings,
  hasPermissionReadInvitations,
  hasPermissionReadMembers,
  hasPermissionReadOrganization,
  hasPermissionReadRoleAssignments,
  hasSAMLIdentity,
  hasSubscription,
  isAccountRestrictedToAccountLinking,
  isAppleUser,
  isAuth0User,
  isDomainClaimedByConnection,
  isEmailVerified,
  isFacebookUser,
  isGoogleUser,
  isLinkable,
  isLoggedInToOrganization,
  isOrganizationMember,
  isSAMLUser,
  isSocialUser,
  isUnlinkedSamlAccount,
};
