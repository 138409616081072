import { User } from '@auth0/auth0-react';
import { ToOptions } from '@tanstack/react-router';

import { RmClaims } from 'src/api/queries/user';

export class AccessDeniedError extends Error {
  constructor(message?: string) {
    super(message);
    this.name = 'AccessDeniedError';
  }
}

export class AccountLinkingMismatchError extends Error {
  constructor(
    // eslint-disable-next-line @typescript-eslint/ban-types
    public redirect: ToOptions['to'] | (string & {}), // Gives autocompletion, but still allows any string
    public currentUser: User | RmClaims,
    public expectedUser: User | RmClaims
  ) {
    super(
      '[AccountLinkingMismatchError]: User id did not match linkable account id'
    );
    this.name = 'AccountLinkingMismatchError';
  }
}

export class EmailNotVerifiedError extends Error {
  constructor(message?: string) {
    super(message);
    this.name = 'EmailNotVerifiedError';
  }
}
