import { useAuth0 } from '@auth0/auth0-react';
import { Check, CircleNotch, EnvelopeSimple } from '@phosphor-icons/react';
import { Typography } from '@remarkable/ark-web';
import { useMutation } from '@tanstack/react-query';

import { storeApi } from 'src/api/endpoints';
import { TestId } from 'src/testids';

import { Button } from './Button';
import { Link } from './Link';
import { NotificationBox } from './NotificationBox';

export function VerifyEmail() {
  const auth = useAuth0();
  const resendEmailVerification = useMutation({
    mutationFn: storeApi.resendEmailVerification,
  });

  return (
    <div
      className="flex min-h-screen w-full animate-fade-in flex-col items-center justify-center space-y-56 px-16 py-64 text-center align-middle"
      data-cy={TestId.PageVerifyEmail}
    >
      <div className="flex-center inline-flex rounded-full bg-gray-500 p-16 text-white">
        {resendEmailVerification.isPending && (
          <CircleNotch className="animate-spin" size={44} />
        )}
        {resendEmailVerification.isIdle && <EnvelopeSimple size={44} />}
        {resendEmailVerification.isSuccess && <Check size={44} />}
      </div>
      <div className="mt-32">
        <Typography variant="heading-lg" as="h1">
          {resendEmailVerification.isSuccess
            ? 'We have sent you a new email'
            : 'Please check your email to verify your account'}
        </Typography>
        <div className="mt-24">
          <Typography variant="body-lg-regular">
            {resendEmailVerification.isSuccess
              ? 'You should receive an email shortly, but please allow a few minutes '
              : 'Please check your email to verify your account. You should receive an email shortly, but please allow a few minutes '}
            for it to arrive
          </Typography>
        </div>
      </div>

      <div>
        <Typography variant="body-lg-regular">Email sent to:</Typography>

        <Typography
          data-cy="email-recipient"
          variant="body-lg-bold"
          className="mt-8"
        >
          {auth.user?.email}
        </Typography>
      </div>

      <div className="flex flex-col items-center justify-center gap-16">
        <Typography variant="body-lg-regular">
          Didn&apos;t find the email?{' '}
          <Link
            inline
            as="button"
            onClick={() => resendEmailVerification.mutate()}
            data-cy="resend-email-button"
          >
            Resend email
          </Link>
        </Typography>

        <Typography variant="body-lg-regular">
          Misspelled or want to use another email?{' '}
          <Link
            inline
            as="button"
            className="font-medium"
            onClick={() => auth.logout()}
          >
            Log out
          </Link>
        </Typography>

        <Typography variant="body-lg-regular">Already verified?</Typography>
        <Button
          className="font-medium"
          onClick={() => {
            window.location.reload();
          }}
        >
          Refresh page
        </Button>
      </div>

      {resendEmailVerification.isError && (
        <div className="inline-flex flex-col items-center">
          <NotificationBox
            data-cy="error-message"
            variant="error"
            title="Unable to send email"
            className="my-16 mt-0 text-left"
          >
            <Typography variant="body-sm-regular">
              Unable to send you another email at this time. Please try again
              later
            </Typography>
          </NotificationBox>
        </div>
      )}
    </div>
  );
}
