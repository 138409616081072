type TestIdPrefix =
  | 'Accordion'
  | 'Block'
  | 'Button'
  | 'Card'
  | 'Checkbox'
  | 'Dropdown'
  | 'Footer'
  | 'Form'
  | 'Header'
  | 'Icon'
  | 'Image'
  | 'Input'
  | 'Label'
  | 'Link'
  | 'List'
  | 'Loader'
  | 'Menu'
  | 'Modal'
  | 'Notification'
  | 'Page'
  | 'Popover'
  | 'Progress'
  | 'Radio'
  | 'Select'
  | 'Sidebar'
  | 'Spinner'
  | 'Table'
  | 'Tabs'
  | 'Tag'
  | 'Text'
  | 'Toast'
  | 'Tooltip';

type TestIdElement<Prefix extends string> = `${Prefix}${string}`;
type TestIdConfig<V extends string> = {
  [K in TestIdElement<V>]: K;
};

/**
 * Test ids for the application
 * Naming convention:
 * - Use `Page` prefix for page level test ids
 * - Use element type as prefix for element level test ids
 */
export const TestId = {
  CheckboxEnterpriseVerifySAML: 'CheckboxEnterpriseVerifySAML',
  PageAccessDenied: 'PageAccessDenied',
  PageEnterpriseEnrollmentAccountLinkingMismatchError:
    'PageEnterpriseEnrollmentAccountLinkingMismatchError',
  PageEnterpriseEnrollmentConfirmSetup: 'PageEnterpriseEnrollmentConfirmSetup',
  PageEnterpriseEnrollmentCreateOrganization:
    'PageEnterpriseEnrollmentCreateOrganization',
  PageEnterpriseEnrollmentSetup: 'PageEnterpriseEnrollmentSetup',
  PageEnterpriseEnrollmentSetupSAML: 'PageEnterpriseEnrollmentSetupSAML',
  PageEnterpriseEnrollmentVerifyDomain: 'PageEnterpriseEnrollmentVerifyDomain',
  PageEnterpriseEnrollmentVerifySAML: 'PageEnterpriseEnrollmentVerifySAML',
  PageEnterpriseMembers: 'PageEnterpriseMembers',
  PageEnterprisePlan: 'PageEnterprisePlan',
  PageHome: 'PageHome',
  PageVerifyEmail: 'PageVerifyEmail',
} as const satisfies TestIdConfig<TestIdPrefix>;

export type TestId = (typeof TestId)[keyof typeof TestId];
