import { useAuth0 } from '@auth0/auth0-react';
import * as Sentry from '@sentry/react';
import {
  createFileRoute,
  invariant,
  Outlet,
  redirect,
} from '@tanstack/react-router';

import { CookieBanner } from 'src/components';
import { IS_DEVELOPMENT } from 'src/config';
import { AccessDeniedError, EmailNotVerifiedError } from 'src/utils/errors';
import { LogCategory } from 'src/utils/logger.categories';
import { sanitizeRedirectUrl } from 'src/utils/sanitizeRedirectUrl';
import { WHITELISTED_ACCOUNT_LINKING_PAGES_FOR_RESTRICTED_ACCOUNTS } from 'src/utils/urls/urls';
import { userHelpers } from 'src/utils/userHelpers';

export const Route = createFileRoute('/_auth')({
  async beforeLoad({ context, location }) {
    const auth = await context.auth; // Wait for the auth context to be ready

    // ========================================================================
    // Auth0 error handling
    // ========================================================================

    if (auth.error) {
      const searchParams = new URLSearchParams(location.searchStr);
      const errorParam = searchParams.get('error');
      Sentry?.withScope((scope) => {
        Sentry?.addBreadcrumb({
          category: 'Auth',
          message: `[auth-guard/auth0-error]: ${[auth.error?.name, errorParam]
            .filter(Boolean)
            .join(' | ')}`,
          data: {
            errorParam,
            error: auth.error?.name,
            errorMessage: auth.error?.message,
          },
        });
        scope.captureException(auth.error);
      });

      switch (errorParam) {
        case 'login_required': {
          Sentry?.addBreadcrumb({
            category: LogCategory.Auth,
            message: 'User not authenticated, redirecting to login',
          });
          throw redirect({
            to: '/login',
            search: {
              login_hint: auth.user && userHelpers.getEmail(auth.user),
              redirect: location.href,
            },
          });
        }
        case 'access_denied': {
          Sentry?.addBreadcrumb({
            category: LogCategory.Auth,
            message:
              'User does not have access to this page, redirecting to /access-denied',
          });
          throw new AccessDeniedError();
        }
        default: {
          /*
          Handle Auth0 'invalid state' error by first doing a no-prompt login
          attempt, then doing a regular login attempt, and finally redirecting
          to an error page if the issue persists.


          Reference:
          https://community.auth0.com/t/invalid-state-on-reload-auth0-callback-url-using-auth0-spa-js-and-angular-8/36469/9

          "[...] In summary, before a login transaction starts auth0-spa-js
          library creates a random state variable and stores it in a cookie.
          Then, it makes a redirect to Auth0 server with this state parameter.
          After the user is authenticated Auth0 returns a code along with the
          same state parameter. Auth-spa-js library checks if the state returned
          matches with the state stored in the cookie and if it matches makes a
          code exchange call to get the issued tokens.

          auth0-spa-js deletes the state cookie just before the code exchange
          request. If the user refreshes the browser before the code exchange
          completes, same code block in the library runs again but this time due
          to the state cookie being deleted, library throws a state mismatch
          error. [...]"

          ---

          It is unclear exactly why we trigger this error, it could be that we
          have some race conditions that interferes with Auth0, but until we
          can figure out the root cause, we will just attempt to handle the
          error when it occurs.
          */
          const loginAttemptsCookie = 'login_attempts=';
          const currentPath = location.href;

          if (
            auth.error.name === 'Error' &&
            auth.error.message === 'Invalid state'
          ) {
            // We need to keep track of the number of login attempts
            // to prevent an infinite loop of login attempts
            const cookies = document.cookie.split(';');
            let loginAttempts = Number(
              cookies
                .find((cookie) => cookie.includes(loginAttemptsCookie))
                ?.split('=')[1]
            );
            loginAttempts = isNaN(loginAttempts) ? 0 : loginAttempts;

            if (loginAttempts < 2) {
              document.cookie = `${loginAttemptsCookie}${loginAttempts + 1};`;
              Sentry.addBreadcrumb({
                category: LogCategory.Auth,
                message: `Login attempt ${
                  loginAttempts + 1
                } for invalid state error`,
              });

              throw redirect({
                to: '/login',
                search: {
                  login_hint: auth.user && userHelpers.getEmail(auth.user),
                  prompt: loginAttempts === 0 ? 'none' : undefined,
                  redirect: currentPath,
                },
              });
            } else {
              document.cookie = `${loginAttemptsCookie}0;`;

              Sentry.addBreadcrumb({
                category: LogCategory.Auth,
                message: 'Too many login attempts for invalid state error',
              });

              throw redirect({ to: '/', replace: true });
            }
          }

          // Reset login-attempts cookie
          document.cookie = `${loginAttemptsCookie}0;`;

          Sentry.captureEvent({
            message: '[Auth]: Unkown authentication error',
            extra: {
              errorParam,
              error: auth.error?.name,
              errorMessage: auth.error?.message,
            },
          });

          throw redirect({
            to: '/authentication-error',
            search: {
              error: errorParam ?? undefined,
              error_description:
                searchParams.get('error_description') ?? undefined,
              redirect_path: currentPath,
            },
          });
        }
      }
    }

    // ========================================================================
    // Auth0 authentication
    // ========================================================================

    if (!auth.isAuthenticated) {
      Sentry?.addBreadcrumb({
        category: 'Auth',
        message: 'User not authenticated, navigating to login',
      });
      throw redirect({
        to: '/login',
        search: {
          login_hint: auth.user && userHelpers.getEmail(auth.user),
          redirect: sanitizeRedirectUrl(location.href),
        },
      });
    } else {
      Sentry?.addBreadcrumb({
        category: 'Auth',
        message: 'User authenticated',
      });
    }

    invariant(auth.user, '[Auth]: User does not exist');

    // ========================================================================
    // Email verification
    // ========================================================================

    if (
      !userHelpers.isEmailVerified(auth.user) &&
      !userHelpers.isSAMLUser(auth.user)
    ) {
      throw new EmailNotVerifiedError();
    }

    // ========================================================================
    // Organization login
    // ========================================================================

    // Sometimes the user can come into a state where they have not properly logged in
    // with organization access. This could happen when the login is not initiated with the
    // orgId or the saml-error login flow is not triggered. The result is that they dont
    // see enterprise and the UI will be misleading without any pointer on how to fix it.

    const isSamlUserInLinkingProcess = userHelpers.isLinkable(auth.user);
    const isLegacyUserInLinkingProcess =
      userHelpers.isDomainClaimedByConnection(auth.user);
    const isUserOrgMemberButNotLoggedIn =
      userHelpers.isOrganizationMember(auth.user) &&
      !userHelpers.isLoggedInToOrganization(auth.user);

    if (
      !isSamlUserInLinkingProcess &&
      !isLegacyUserInLinkingProcess &&
      isUserOrgMemberButNotLoggedIn
    ) {
      Sentry?.addBreadcrumb({
        category: LogCategory.Auth,
        message:
          'User is org member but not logged in with org access, triggering silent org login...',
        data: IS_DEVELOPMENT
          ? {
              currentLocation: sanitizeRedirectUrl(location.href),
            }
          : undefined,
      });

      throw redirect({
        to: '/login',
        search: {
          prompt: 'none',
          redirect: sanitizeRedirectUrl(location.href),
          organization: userHelpers.getOrganizationId(auth.user),
        },
      });
    }

    // ========================================================================
    // Account linking
    // ========================================================================

    const isCurrentlyLinkingAccounts = !!location.searchStr.includes(
      'accountLinkingToken'
    );

    if (
      userHelpers.isAccountRestrictedToAccountLinking(auth.user) &&
      !isCurrentlyLinkingAccounts
    ) {
      const isOnWhitelistedPage =
        WHITELISTED_ACCOUNT_LINKING_PAGES_FOR_RESTRICTED_ACCOUNTS.findIndex(
          (path) => path && location.pathname.startsWith(path)
        ) > 0;

      if (!isOnWhitelistedPage) {
        if (userHelpers.hasActiveEnterpriseEnrollment(auth.user)) {
          Sentry.addBreadcrumb({
            category: LogCategory.Auth,
            message:
              'User has active enterprise enrollment, redirecting to enrollment',
          });
          throw redirect({
            to: '/enterprise/enroll',
            replace: true,
          });
        }

        Sentry.addBreadcrumb({
          category: LogCategory.Auth,
          message:
            'User is restricted to account linking, redirecting to account linking',
        });
        throw redirect({
          to: '/account/migrate/saml',
          replace: true,
        });
      }
    }
  },

  component: () => {
    const auth = useAuth0();
    return (
      <>
        <Outlet />
        {!auth.isLoading && auth.isAuthenticated && <CookieBanner />}
      </>
    );
  },
});
